<template>
  <div class="view">
    <video
      id="video"
      :src="detail.video"
      :poster="detail.pic"
      controls
      controlsList="nodownload noplaybackrate"
      disablePictureInPicture
    ></video>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {
      id: null,
      detail: {}
    }
  },

  filters: {},
  computed: {},
  watch: {},

  created() {
    this.id = this.$route.query.id
    this.getDetail()
  },

  methods: {
    async getDetail() {
      const res = await this.$api.course.getCourseDetail(this.id)
      this.detail = res.data
      document.title = this.detail.title
    }
  }
}
</script>

<style lang="scss" scoped>
.view {
  height: 100%;

  #video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
